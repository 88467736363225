<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">List Profil PTSP Kabupaten / Kota</h3>
                    <div class="card-toolbar">
                        
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-6">
                        <b-button :href="url+'/export/status_profil'" class="mb-2" variant="primary"><i class="fa fa-download" aria-hidden="true"/>Excel </b-button>
                        <div>
                            <div style="overflow-x:auto; overflow-y: hidden;">
                                <table id="example" class="table table-striped table-bordered" style="width:100%">
                                  <thead>
                                      <tr>
                                          <th rowspan="2">No</th>
                                          <th rowspan="2">Nama Kabupaten / Kota</th>
                                          <th rowspan="2">Kepala Dinas</th>
                                          <th rowspan="2">Email</th>
                                          <th rowspan="2">Contact Person</th>
                                          <th style="text-align:center;" colspan="2">Contact Person 1</th>
                                          <th style="text-align:center;" colspan="2">Contact Person 2</th>
                                          <th rowspan="2">Status</th>
                                      </tr>
                                      <tr>
                                        <th>Email</th>
                                        <th>No Telp</th>
                                        <th>Email</th>
                                        <th>No Telp</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="row in kabkot" :key="row.id">
                                          <td></td>
                                          <td><div style="width:100px">{{row.daerah}}</div></td>
                                          <td>{{row.nama_kepala}}</td>
                                          <td>{{row.email}}</td>
                                          <td>
                                            <div style="width:150px;">
                                              <ol>
                                                <li>{{row.nama_contact_person1}}</li>
                                                <li>{{row.nama_contact_person2}}</li>
                                              </ol>
                                            </div>
                                          </td>
                                          <td>{{row.email_contact_person1}}</td>
                                          <td>{{row.no_contact_person1}}</td>
                                          <td>{{row.email_contact_person2}}</td>
                                          <td>{{row.no_contact_person2}}</td>
                                          <td>{{row.status_profil}}</td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>

        </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';

export default {
  data() {
    return {
      url: localStorage.getItem('baseapi'),
      token: localStorage.getItem('id_token'),
      kabkot:[],
      role:'ptsp_kabkot',
      exportexcell:'https://penilaiankinerja.bkpm.go.id:3010/export/status_profil'
    };
  },
  components: {
    KTCodePreview
  },
  methods: {
    getdata(){
      $('#example').DataTable().destroy();
        this.loaddata()
    },
    initDatatable() {
        setTimeout(() => {
        var t =  $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            order: [[0, 'desc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    loaddata(){
      axios.get(this.url+"/profil?filter=idptsp,>,99", {
        headers: {
            'xth': this.token
        }
      })
      .then(response => {
          this.kabkot = response.data.data;
          this.initDatatable()
      }).catch(error=>{
          return error
          });
    },
    urlprofil(kode_wilayah){
      let routeData = this.$router.resolve(
        {
          path: `/list-peserta/profilptspdetail/${kode_wilayah}`,
        });
        window.open(routeData.href, '_blank');
    },
    lihat(id){
        localStorage.setItem('idprofil', id)
        this.$router.push({ name: "Profil-PTSP" })
    },
    kirimakun(idptsp){
      const id = idptsp;
      const role = this.role;
      axios
        .post(
          this.url+"/mailing",
          {
            id,
            role,
          },{
            headers: {
              "Content-type": "application/json",
              "xth": this.token,
              }
          }
          )
        .then((res) => {
            this.$bvToast.toast('Berhasil Dikirim', {
                title: `Success `,
                variant: `success`,
                solid: true
            })
            // this.loaddata()
            this.loaddata()
            // localStorage.setItem('idptsp', JSON.stringify(res.data.data.idptsp))
          return res;
        })
        .catch((err) => {
          console.log(err)
          this.$bvToast.toast('Gagal Disimpan', {
          title: `Failed `,
          variant: `danger`,
          solid: true
        })
          return err;
        });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  mounted() {
    this.getdata()
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "List Profil PTSP Kabupaten / Kota" }
    ]);
  }
};
</script>
